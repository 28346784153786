<template>
  <img
    v-if="media?.mimeType?.startsWith('image')"
    ref="$mediaRef"
    :srcset="computedMediaSrcSet"
    :src="media?.url"
    :alt="altText || media?.alt || undefined"
    :class="imageClassNames"
    :title="title || media?.title || undefined"
    :sizes="sizes"
  />
  <video
    v-else-if="isMediaCollection"
    ref="$mediaRef"
    :class="videoClassNames"
    autoplay
    loop
    muted
    playsinline
    :title="title || undefined"
    :sizes="sizes"
  >
    <source
      v-for="(source, format) in videoSources"
      :key="format"
      :src="source?.url"
      :type="source?.mimeType"
    />
  </video>
  <div
    v-else-if="media?.mimeType?.startsWith('video') && showControls"
    class="relative"
    @click="togglePlay"
  >
    <transition name="fade" mode="out-in">
      <IconPlay
        v-if="!isVideoPlaying"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10"
      />
    </transition>
    <video
      ref="$videoRef"
      :src="media?.url"
      :alt="media?.alt"
      :class="videoClassNames"
      loop
      muted
      playsinline
      :title="title || media?.title || undefined"
      class="w-full max-w-[72.5rem] min-h-[13.25rem] h-auto md:h-[40.625rem] mx-auto"
      :sizes="sizes"
      :type="media?.mimeType"
    ></video>
  </div>
  <video
    v-else-if="media?.mimeType?.startsWith('video')"
    ref="$mediaRef"
    :src="media?.url"
    :alt="media?.alt"
    :class="videoClassNames"
    :title="title || media?.title || undefined"
    autoplay
    loop
    muted
    playsinline
    :sizes="sizes"
    :type="media?.mimeType"
  ></video>
  <ImgPlaceholder v-else :class="imageClassNames" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { getMediaSrcSet } from "@/utils/media/getMediaSrcSet";
import type { Media } from "@shopware-pwa/types";
import ImgPlaceholder from "@/components/atoms/ImgPlaceholder";
import { IconPlay } from "@/components/atoms/Icon";

const props = defineProps<{
  media?: Media;
  videoClassNames?: string;
  imageClassNames?: string;
  sizes?: string;
  altText?: string;
  showControls?: boolean;
  disableSrcSet?: boolean;
  title?: string;
}>();

const $mediaRef = ref<HTMLImageElement | HTMLVideoElement | null>(null);
const $videoRef = ref<HTMLVideoElement | null>(null);
const isVideoPlaying = ref(false);

const isMediaCollection = computed(() => {
  return (
    props.media &&
    typeof props.media === "object" &&
    ("av1" in props.media ||
      "h264" in props.media ||
      "hevc" in props.media ||
      "webm" in props.media)
  );
});

const videoSources = computed<Record<string, Media>>(() => {
  let mediaObject: Record<string, Media> = {};
  if (isMediaCollection.value && props.media) {
    mediaObject = props.media as unknown as Record<string, Media>;
  }

  return Object.entries(mediaObject).reduce((acc, [format, media]) => {
    if (!media?.url || !media?.mimeType) return acc;
    return {
      ...acc,
      [format]: media,
    };
  }, {});
});

const computedMediaSrcSet = computed(() => {
  return props.disableSrcSet ? "" : getMediaSrcSet(props.media ?? null);
});

// TODO: Create VideoComponent later and move video logic to this component
const togglePlay = () => {
  if ($videoRef.value) {
    if ($videoRef.value.paused) {
      $videoRef.value.play();
      $videoRef.value.controls = true;
      isVideoPlaying.value = true;
    } else {
      $videoRef.value.pause();
      $videoRef.value.controls = false;
      isVideoPlaying.value = false;
    }
  }
};

defineExpose({
  ref: $mediaRef,
});
</script>
